






















































import Vue, { PropType } from "vue";
import { Connection } from "@/store/connections/types";
import { pagingDefault, Paging } from "@/store/utils/";
import { namespace } from "vuex-class";
import { Utils } from "@/utils/";
import { ConnectionsBody } from "../../api/connections";

const connectionsModule = namespace("connections");
const shopModule = namespace("shops");

export default Vue.extend({
  name: "PendingCustomers",

  computed: {
    pendingCustomers: function () {
      return this.$store.getters["connections/pendingCustomers"];
    },
    _pagination: {
      get: function () {
        return this.$store.state.connections.pagination;
      },
      set: function (value) {
        this.$store.dispatch("connections/paginate", value);
      },
    },
    loading: function () {
      return this.$store.state.connections.loading;
    },
    pagination: function () {
      return this.$store.state.connections.pagination;
    },
    totalItems: function () {
      return this.$store.state.connections.totalItems;
    },
  },

  data: function () {
    return {
      deleteConfirm: false,
      searchVal: "",
      showDisabled: false,

      headers: [
        { text: "Customer Code", value: "customerCode" },
        { text: "Customer Name", value: "customerName" },
        { text: "Types", value: "types" },
        { text: "Contact Name", value: "contactName" },
        { text: "Contact Number", value: "contactNumber" },
        { text: "Contact Address", value: "contactAddress" },
        { text: "Created", value: "dateCreated", type: "dateTime" },
        {
          text: "Approve Customer",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],

      // This is temp solution until we can filter by organisation on api side
      paginationTemp: { ...pagingDefault },
    };
  },

  created: function () {
    this.$store.dispatch("connections/fetchConnectionsPending", {
      status: "PENDING",
      showDisabled: false,
    });
  },

  mounted: function () {
    this._pagination = { ...pagingDefault, itemsPerPage: -1 };
  },

  methods: {
    onNextPage(newx: any, oldx: any) {
      this.$store.dispatch("connections/fetchConnectionsPending", {
        status: "PENDING",
        showDisabled: false,
      });
    },
    onShopChange(newx: any, oldx: any) {
      this.$store.dispatch("connections/fetchConnectionsPending", {
        status: "PENDING",
        showDisabled: false,
      });
    },
    async onApprove(connectionId: number) {
      const success = await this.$store.dispatch(
        "connections/acceptConnection",
        connectionId
      );
      if (success)
        this.$store.dispatch("connections/fetchConnectionsPending", {
          status: "PENDING",
          showDisabled: false,
        });
    },
    async onDecline(connectionId: number) {
      const success = await this.$store.dispatch(
        "connections/declineConnection",
        connectionId
      );
      if (success)
        this.$store.dispatch("connections/fetchConnectionsPending", {
          status: "PENDING",
          showDisabled: false,
        });
    },
    formatColumn(header: any, item: any) {
      return Utils.formatColumn(header, item);
    },
  },
});
