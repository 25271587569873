var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":"Pending Invites"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.deleteConfirm),callback:function ($$v) {_vm.deleteConfirm=$$v},expression:"deleteConfirm"}},[_c('S2SForm',{attrs:{"title":"You are deleting your connection"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pendingCustomers,"loading":_vm.loading,"options":_vm.paginationTemp},on:{"update:options":function($event){_vm.paginationTemp=$event}},scopedSlots:_vm._u([{key:"item.contactAddress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contactAddress)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.direction === 'sell')?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_horiz")])]}}],null,true)},[_c('span',[_vm._v("Waiting for approval")])])],1):(item.direction !== 'sell')?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.onApprove(item.id)}}},on),[_c('v-icon',[_vm._v("check")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.onDecline(item.id)}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}],null,true)},[_c('span',[_vm._v("Decline")])])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }